import React, { useState } from 'react';
import { Box, Typography, Button, useTheme, useMediaQuery } from '@mui/material';

const KevinsKorner = () => {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const previewText = `Dear Axillon Aerospace Team Members,

As we observe Veterans Day, I would like to take a moment to express our heartfelt gratitude to those of you who have served in the military...`;

  const fullText = `Dear Axillon Aerospace Team Members,

As we observe Veterans Day, I would like to take a moment to express our heartfelt gratitude to those of you who have served in the military. Your dedication, sacrifice, and commitment to protecting our freedoms do not go unnoticed.

We are proud to have you as part of Axillon Aerospace, and we appreciate the unique perspectives and skills you bring from your military experience. Thank you for your bravery, commitment, and values you uphold.

Today, let us honor all veterans and reflect on the importance of their contributions to our country.
...
`;

  return (
    <Box
      sx={{
        backgroundColor: '#a4d06a',
        padding: 3,
        borderRadius: 2,
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        margin: '1em 0',
        //marginTop: '50px', // Offset to prevent overlap with Navbar
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          alignItems: isSmallScreen ? 'center' : 'flex-start',
          gap: 2,
        }}
      >
        <Box
          component="img"
          src="/Kevin.png"
          alt="Kevin smiling"
          sx={{
            width: 80,
            height: 80,
            borderRadius: '50%',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          }}
        />
        <Box sx={{ textAlign: isSmallScreen ? 'center' : 'left' }}>
          <Typography variant="h4" sx={{ mb: 1, color: 'primary.main' }}>
            Kevin's Corner
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
            November 11, 2024
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: 'text.primary',
              whiteSpace: 'pre-line',
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: expanded ? 'unset' : 5,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {expanded ? fullText : previewText}
          </Typography>
          <Button
            onClick={toggleExpand}
            sx={{
              marginTop: 2,
              textTransform: 'none',
              color: 'primary.main',
              fontWeight: 'bold',
            }}
          >
            {expanded ? 'Show Less' : 'Read More'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default KevinsKorner;