import React, { useState, useEffect } from 'react';
import { 
  Navbar,
  NavbarBrand, 
  Nav, 
  NavItem, 
  NavLink, 
  NavbarToggler, 
  Collapse, 
  Button 
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faShieldAlt, faStamp, faCertificate, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    // Clear session-related data
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    document.cookie = "csrftoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    
    // Redirect to home or login page
    window.location.href = '/login';
  };

  useEffect(() => {
    // Check if the user is logged in by looking for an access token
    const token = localStorage.getItem('accessToken');
    setIsLoggedIn(!!token);
  }, []);

  return (
    <Navbar
      color="light"
      light
      expand="md"
      className="fixed-top shadow"
      style={{
        padding: "20px 0",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <NavbarBrand href="/" className="d-flex align-items-center">
        <img
          src="/axillion-aerospace-logo.svg" // Use absolute path
          width="150"
          alt="Axillion Aerospace Logo"
        />
      </NavbarBrand>
      <NavbarToggler onClick={toggleNavbar} className="border-0" />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ms-auto d-flex align-items-center" navbar>
          <NavItem>
            <NavLink
              href="/"
              className="d-flex align-items-center px-4 py-2"
              style={{
                color: "#333",
                fontWeight: "500",
                borderRadius: "8px",
                transition: "background-color 0.3s ease",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#f1f1f1")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent")}
            >
              <FontAwesomeIcon icon={faHome} style={{ marginRight: "8px" }} />
              Home
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="/"
              className="d-flex align-items-center px-4 py-2"
              style={{
                color: "#333",
                fontWeight: "500",
                borderRadius: "8px",
                transition: "background-color 0.3s ease",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#f1f1f1")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent")}
            >
              <FontAwesomeIcon icon={faStamp} style={{ marginRight: "8px" }} />
              Quality Policies
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="/"
              className="d-flex align-items-center px-4 py-2"
              style={{
                color: "#333",
                fontWeight: "500",
                borderRadius: "8px",
                transition: "background-color 0.3s ease",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#f1f1f1")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent")}
            >
              <FontAwesomeIcon icon={faCertificate} style={{ marginRight: "8px" }} />
              Policies
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="/ehs"
              className="d-flex align-items-center px-4 py-2"
              style={{
                color: "#333",
                fontWeight: "500",
                borderRadius: "8px",
                transition: "background-color 0.3s ease",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#f1f1f1")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent")}
            >
              <FontAwesomeIcon icon={faShieldAlt} style={{ marginRight: "8px" }} />
              EHS
            </NavLink>
          </NavItem>
          {isLoggedIn && (
            <NavItem>
              <Button
                color="danger"
                className="d-flex align-items-center px-4 py-2"
                style={{
                  color: "#fff",
                  fontWeight: "500",
                  borderRadius: "8px",
                  transition: "background-color 0.3s ease",
                  marginLeft: "10px",
                }}
                onClick={handleLogout}
              >
                <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: "8px" }} />
                Logout
              </Button>
            </NavItem>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Header;