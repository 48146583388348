import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography } from '@mui/material';

const ResetPasswordForm = () => {
  const { uidb64, token } = useParams(); // Get the parameters from the URL
  const navigate = useNavigate(); // Hook for navigation
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await fetch('https://api.axillonaerospace.info/reset-password-confirm/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uidb64, token, password }),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage('Password successfully reset. Redirecting to home...');
        setError('');
        setTimeout(() => navigate('/'), 3000); // Redirect after 3 seconds
      } else {
        setMessage('');
        setError(data.detail || 'Failed to reset password.');
      }
    } catch (err) {
      setError('An unexpected error occurred. Please try again.');
      setMessage('');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: 2,
      }}
    >
      <Typography variant="h4" mb={2}>
        Reset Password
      </Typography>
      {message && (
        <Typography
          color="success.main"
          mb={2}
          aria-live="polite"
          role="alert"
        >
          {message}
        </Typography>
      )}
      {error && (
        <Typography
          color="error.main"
          mb={2}
          aria-live="assertive"
          role="alert"
        >
          {error}
        </Typography>
      )}
      <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
        <TextField
          label="New Password"
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
          disabled={isSubmitting}
        />
        <TextField
          label="Confirm Password"
          variant="outlined"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
          disabled={isSubmitting}
        />
        <Button
          variant="contained"
          type="submit"
          fullWidth
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Resetting...' : 'Reset Password'}
        </Button>
      </form>
    </Box>
  );
};

export default ResetPasswordForm;