import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Container, Box } from '@mui/material';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import Ehs from './components/ehs';
import ContactPage from './components/ContactPage';
import Login from './components/LoginPage';
import PasswordReset from './components/PasswordReset';
import Register from './components/RegisterPage'; // New Register Page
import PrivateRoute from './components/PrivateRoute';
import ResetPasswordForm from './components/ResetPasswordForm';
import { AuthProvider } from './context/AuthContext';
import { refreshToken } from './utils/auth'; // Import refreshToken
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga4';

// Initialize Google Analytics
ReactGA.initialize('G-41QLH62W9Y');

// Track page views
const usePageTracking = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);
};

// Automatically refresh tokens before expiration
const useTokenRefresh = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      refreshToken();
    }, 14 * 60 * 1000); // Refresh 1 minute before the 15-minute expiration
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);
};

const AppRoutes = () => {
  usePageTracking();
  useTokenRefresh(); // Add token refresh logic

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/password-reset" element={<PasswordReset />} />
      <Route path="/reset-password/:uidb64/:token" element={<ResetPasswordForm />} />

      {/* Private Routes */}
      <Route path="/" element={<PrivateRoute element={<HomePage />} />} />
      <Route path="/ehs" element={<PrivateRoute element={<Ehs />} />} />
      <Route path="/contact" element={<PrivateRoute element={<ContactPage />} />} />
    </Routes>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Container maxWidth="xl" sx={{ padding: 4 }}>
          {/* Header: Always visible */}
          <Header />

          {/* Main Content: Includes routes */}
          <Box sx={{ paddingTop: '1rem' }}>
            <AppRoutes />
          </Box>

          {/* Footer: Always visible */}
          <Footer />
        </Container>
      </Router>
    </AuthProvider>
  );
};

export default App;