import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faNetworkWired, 
  faChartBar,
  faChartLine, 
  faHeart, 
  faCoins, 
  faClock, 
  faChalkboardTeacher,
  faServer,
  faShieldAlt,
  faKey,
  faUsers,
  faLightbulb,
  faBriefcase,
  faPlane
} from '@fortawesome/free-solid-svg-icons';

const Links = () => (
  <Box
    sx={{
      backgroundColor: '#f9f9f9',
      padding: 3,
      borderRadius: 2,
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
      marginBottom: 4,
      textAlign: 'center',
      transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
        },
    }}
  >
    

    {/* Corporate Section */}
    <Box
      sx={{
        marginBottom: 4,
      }}
    >
      <Typography variant="h4" sx={{ mb: 2, color: 'primary.main'  }}>
        Corporate Links
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        
        <Button
          variant="contained"
          color="secondary"
          startIcon={<FontAwesomeIcon icon={faChartBar} />}
          aria-label="Tableau (Analytics)"
          onClick={() => window.open('http://usbipvtableau/#/home', '_blank')}
          sx={{
            padding: 2,
            minWidth: 200,
          }}
        >
          Tableau (Analytics)
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<FontAwesomeIcon icon={faHeart} />}
          aria-label="Benefits Portal"
          onClick={() => window.open('https://carbontopco.bswift.com/', '_blank')}
          sx={{
            padding: 2,
            minWidth: 200,
          }}
        >
          Benefits Portal
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<FontAwesomeIcon icon={faUsers} />}
          aria-label="UKG Pro"
          onClick={() => window.open('https://skcappartners.ukg.net', '_blank')}
          sx={{
            padding: 2,
            minWidth: 200,
          }}
        >
          UKG Pro
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<FontAwesomeIcon icon={faBriefcase} />}
          aria-label="Recruiting"
          onClick={() => window.open('https://axillonaerospace-openhire.silkroad.com/', '_blank')}
          sx={{
            padding: 2,
            minWidth: 200,
          }}
        >
          Recruiting
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<FontAwesomeIcon icon={faPlane} />}
          aria-label="Travel Bank"
          onClick={() => window.open('https://app.travelbank.com/user/login?active=email', '_blank')}
          sx={{
            padding: 2,
            minWidth: 200,
          }}
        >
          Travel Bank
        </Button>
      </Box>
    </Box>

    {/* Rockmart Section */}
    <Box
      sx={{
        marginTop: 4,
      }}
    >
      <Typography variant="h4" sx={{ mb: 2, color: 'primary.main'   }}>
        Rockmart Links
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        {/* 
          <Button
            variant="contained"
            color="warning"
            startIcon={<FontAwesomeIcon icon={faHeart} />}
            aria-label="MDG"
            onClick={() =>
              window.open(
                'https://vhmegspplb.hec.meggitt.com/fiori?sap-client=100&sap-language=EN#Shell-home',
                '_blank'
              )
            }
            sx={{
              padding: 2,
              minWidth: 200,
            }}
          >
            MDG
          </Button>
          */}
        <Button
          variant="contained"
          color="warning"
          startIcon={<FontAwesomeIcon icon={faChartBar} />}
          aria-label="Rockmart Power BI link"
          onClick={() =>
            window.open(
              'https://app.powerbi.com/home',
              '_blank'
            )
          }
          sx={{
            padding: 2,
            minWidth: 200,
          }}
        >
          Rockmart Power BI
        </Button>
        <Button
          variant="contained"
          color="warning"
          startIcon={<FontAwesomeIcon icon={faChartBar} />}
          aria-label="Rockmart Data Analytics"
          onClick={() =>
            window.open(
              'https://parkercorp.sharepoint.com/sites/Meggitt_RockmartDataAnalytics',
              '_blank'
            )
          }
          sx={{
            padding: 2,
            minWidth: 200,
          }}
        >
          Data Analytics
        </Button>
        <Button
          variant="contained"
          color="warning"
          startIcon={<FontAwesomeIcon icon={faCoins} />}
          aria-label="LOA/FMLA link"
          onClick={() =>
            window.open(
              'http://linclonfinancial.com',
              '_blank'
            )
          }
          sx={{
            padding: 2,
            minWidth: 200,
          }}
        >
          LOA/FMLA link
        </Button>
        <Button
          variant="contained"
          color="warning"
          startIcon={<FontAwesomeIcon icon={faClock} />}
          aria-label="Timekeeping"
          onClick={() =>
            window.open(
              'https://meggittusa.prd.mykronos.com',
              '_blank'
            )
          }
          sx={{
            padding: 2,
            minWidth: 200,
          }}
        >
          Timekeeping
        </Button>
        <Button
          variant="contained"
          color="warning"
          startIcon={<FontAwesomeIcon icon={faChalkboardTeacher} />}
          aria-label="Training Portal"
          onClick={() =>
            window.open(
              'https://meggitt-8585.reach360.com/learn/',
              '_blank'
            )
          }
          sx={{
            padding: 2,
            minWidth: 200,
          }}
        >
          Training Portal
        </Button>
      </Box>
      <Box
      sx={{
        marginTop: 4,
      }}
    >
      <Typography variant="h4" sx={{ mb: 2, color: 'primary.main'   }}>
        San Diego Links
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: 2,
        }}
        > 
        <Button
          variant="contained"
          color="warning"
          startIcon={<FontAwesomeIcon icon={faNetworkWired} />}
          aria-label="SCADA System"
          onClick={() => window.open('http://usbipvscada01:8090/', '_blank')}
          sx={{
            padding: 2,
            minWidth: 200,
          }}
        >
          SCADA System
        </Button>
          <Button
          variant="contained"
          color="warning"
          startIcon={<FontAwesomeIcon icon={faChartLine} />}
          aria-label="Continuous Improvement"
          onClick={() =>
            window.open(
              'https://axillon.atlassian.net/jira/core/projects/CI2/board',
              '_blank'
            )
          }
          sx={{
            padding: 2,
            minWidth: 200,
          }}
        >
          Continuous Improvement
        </Button>
        <Button
          variant="contained"
          color="warning"
          startIcon={<FontAwesomeIcon icon={faLightbulb} />}
          aria-label="Submit a bright idea"
          onClick={() =>
            window.open(
              'http://usbipvpython:2005/',
              '_blank'
            )
          }
          sx={{
            padding: 2,
            minWidth: 200,
          }}
        >
          Submit a bright idea
        </Button>
        <Button
          variant="contained"
          color="warning"
          startIcon={<FontAwesomeIcon icon={faClock} />}
          aria-label="Timekeeping"
          onClick={() =>
            window.open(
              'http://usbipvm2msfdc:8080/unanet/action/home',
              '_blank'
            )
          }
          sx={{
            padding: 2,
            minWidth: 200,
          }}
        >
          Timekeeping
        </Button>
          <Button
            variant="contained"
            color="warning"
            startIcon={<FontAwesomeIcon icon={faChalkboardTeacher} />}
            aria-label="Doc Control & Training"
            onClick={() =>
              window.open(
                'http://usbipvmcapp01.int1.meggittplc.com/mc/login/index.cfm?action=displayLoginPage&message=MCP_EVENT_MSG_9',
                '_blank'
              )
            }
            sx={{
              padding: 2,
              minWidth: 200,
            }}
          >
            Doc Control & Training
          </Button>
        
        </Box>
        </Box>
      <Box
      sx={{
        marginTop: 4,
      }}
    >
      <Typography variant="h4" sx={{ mb: 2, color: 'primary.main'   }}>
        IT Links
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: 2,
        }}
        >
          <Button
          variant="contained"
          color="primary"
          startIcon={<FontAwesomeIcon icon={faServer} />}
          aria-label="San Diego Vsphere"
          onClick={() =>
            window.open(
              'https://usbipvcsa70.int1.meggittplc.com/',
              '_blank'
            )
          }
          sx={{
            padding: 2,
            minWidth: 200,
          }}
        >
          San Diego Vsphere
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FontAwesomeIcon icon={faServer} />}
          aria-label="Saltillo Vsphere"
          onClick={() =>
            window.open(
              'https://usbipvcsa70.int1.meggittplc.com/',
              '_blank'
            )
          }
          sx={{
            padding: 2,
            minWidth: 200,
          }}
        >
          Saltillo Vsphere
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FontAwesomeIcon icon={faShieldAlt} />}
          aria-label="Forticlient"
          onClick={() =>
            window.open(
              'https://10.212.9.108/login ',
              '_blank'
            )
          }
          sx={{
            padding: 2,
            minWidth: 200,
          }}
        >
          FortiClient
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FontAwesomeIcon icon={faKey} />}
          aria-label="Bit Locker Recovery"
          onClick={() =>
            window.open(
              'https://gb1ipvsccm02.meggitt.net/helpdesk/Default.aspx',
              '_blank'
            )
          }
          sx={{
            padding: 2,
            minWidth: 200,
          }}
        >
          Bit Locker Recovery
        </Button>
        </Box> 
        </Box>     
    </Box>
  </Box>
);

export default Links;

