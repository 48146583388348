export const refreshToken = async () => {
    try {
      const refreshToken = document.cookie
        .split('; ')
        .find((row) => row.startsWith('refresh_token='))
        ?.split('=')[1];
  
      if (refreshToken) {
        const response = await fetch(
          'https://api.axillonaerospace.info/api/token/refresh/',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refresh: refreshToken }),
          }
        );
  
        if (response.ok) {
          const data = await response.json();
          console.log('Token refreshed:', data);
  
          // Update access token in localStorage
          const authData = JSON.parse(localStorage.getItem('authData')) || {};
          authData.accessToken = data.access;
          authData.expiration = new Date().getTime() + 15 * 60 * 1000; // 15 minutes
          localStorage.setItem('authData', JSON.stringify(authData));
          return data.access;
        } else {
          console.error('Token refresh failed');
        }
      }
    } catch (err) {
      console.error('Error refreshing token:', err);
    }
  };