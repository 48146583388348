import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import Cookies from 'js-cookie';
import axios from 'axios';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [csrfToken, setCsrfToken] = useState(''); // State to store CSRF token

  useEffect(() => {
    const fetchCSRFToken = async () => {
      try {
        const response = await fetch(
          'https://api.axillonaerospace.info/get-csrf-token/',
          {
            method: 'GET',
            credentials: 'include',
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCsrfToken(data.csrfToken); // Store the CSRF token in state
          //console.log('CSRF Token from Response:', data.csrfToken);
        } else {
          console.error('Failed to fetch CSRF token:', response);
        }
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };

    fetchCSRFToken();
  }, []);

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    setMessage('');
    setError('');

    // Simple email validation
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    setIsSubmitting(true);

    try {
      // Use the CSRF token from the response or cookies
      const csrfTokenToUse = Cookies.get('csrftoken') || csrfToken;

      if (!csrfTokenToUse) {
        console.error('CSRF token not found.');
        setError('Unable to complete request. Please refresh the page and try again.');
        setIsSubmitting(false);
        return;
      }

      const response = await axios.post(
        'https://api.axillonaerospace.info/password-reset/',
        { email },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
          withCredentials: true, // Include cookies in the request
        }
      );

      if (response.status === 200) {
        setMessage('If the email exists, a password reset link has been sent.');
        console.log('Password reset email sent:', response.data);
        console.log('response:', response); // Log the response object
        setError('');
      } else {
        setError('Error sending reset email.');
      }
    } catch (error) {
      if (error.response) {
        setError(error.response.data.detail || 'Error sending reset email.');
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
      console.error('Error during password reset:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: 2,
      }}
    >
      <Typography variant="h4" mb={2}>
        Reset Password
      </Typography>
      {message && (
        <Typography color="success.main" mb={2} aria-live="polite" role="alert">
          {message}
        </Typography>
      )}
      {error && (
        <Typography color="error.main" mb={2} aria-live="assertive" role="alert">
          {error}
        </Typography>
      )}
      <form onSubmit={handlePasswordReset} style={{ width: '100%', maxWidth: '400px' }}>
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
          disabled={isSubmitting}
        />
        <Button
          variant="contained"
          type="submit"
          fullWidth
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Sending...' : 'Send Reset Link'}
        </Button>
      </form>
    </Box>
  );
};

export default PasswordReset;