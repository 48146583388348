import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, Link } from '@mui/material';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        'https://api.axillonaerospace.info/api/users/login/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        }
      );

      if (response.ok) {
        const data = await response.json();

        // Save refresh token in httpOnly cookie
        document.cookie = `refresh_token=${data.refresh_token}; path=/; max-age=${30 * 24 * 60 * 60}; secure; httponly; samesite=strict`;

        // Save access token in memory or localStorage
        const expirationTime = new Date().getTime() + 15 * 60 * 1000; // 15 minutes
        const authData = {
          isAuthenticated: true,
          accessToken: data.access_token,
          expiration: expirationTime,
        };
        localStorage.setItem('authData', JSON.stringify(authData));

        // Redirect to the home page
        navigate('/');
      } else {
        const errorData = await response.json();
        //console.error('Login failed:', errorData);
        setError(errorData.detail || 'Invalid email or password');
      }
    } catch (err) {
      //console.error('Error during login:', err);
      setError('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: 2,
      }}
    >
      <Typography variant="h4" mb={2}>
        Login
      </Typography>
      {error && (
        <Typography color="error" mb={2}>
          {error}
        </Typography>
      )}
      <form onSubmit={handleLogin} style={{ width: '100%', maxWidth: '400px' }}>
        <TextField
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <Button variant="contained" type="submit" fullWidth>
          Login
        </Button>
      </form>
      <Box mt={2}>
        <Link href="/password-reset" variant="body2">
          Forgot your password?
        </Link>
      </Box>
      <Box mt={1}>
        <Link href="/register" variant="body2">
          Don't have an account? Register here.
        </Link>
      </Box>
    </Box>
  );
};

export default Login;